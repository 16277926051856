import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { memo } from "react";
import classes from "./classes.module.css";
import { useLang } from "../../../common/contexts/lang/context";
import CopyToClipboard from "../../CopyToClipboard";
import CloseButton from "../CloseButton";
import CustomLink from "../../CustomLink";

type Props = {
  isOpen: boolean;
  close: () => void;
};

function ContactDialog({ isOpen: open, close }: Readonly<Props>) {
  const theme = useTheme();
  const { dictionary } = useLang();

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          <Typography
            color={theme.palette.primary.dark}
            fontSize={22}
            fontWeight={400}
          >
            {dictionary.dialogs.contact.title}
          </Typography>

          <CloseButton onClick={close} />
        </DialogTitle>

        <DialogContent>
          <Box className={classes.root}>
            <Box className={classes.email}>
              <Typography>{dictionary.dialogs.contact.email}</Typography>
              <Typography>rael06@hotmail.fr</Typography>
              <CopyToClipboard value="rael06@hotmail.fr" />
            </Box>

            <CustomLink
              to="https://drive.google.com/file/d/1NMV-yXMCo7eZX-Avj2mMMJjzKoJcIDq8/view?usp=sharing"
              target="_blank"
            >
              <Typography color={theme.palette.primary.dark}>
                {dictionary.dialogs.contact.cv}
              </Typography>
            </CustomLink>

            <CustomLink to="https://github.com/rael06" target="_blank">
              <Typography color={theme.palette.primary.dark}>
                {dictionary.dialogs.contact.githubPerso}
              </Typography>
            </CustomLink>

            <CustomLink to="https://github.com/RaelCalitro" target="_blank">
              <Typography color={theme.palette.primary.dark}>
                {dictionary.dialogs.contact.githubPro}
              </Typography>
            </CustomLink>

            <Box className={classes.linkedin}>
              <CustomLink
                to="https://www.linkedin.com/in/rael-calitro-4a519a187"
                target="_blank"
              >
                <Typography color={theme.palette.primary.dark}>
                  LinkedIn
                </Typography>
              </CustomLink>

              <Box className={classes.linkedinQrCodeContainer}>
                <img
                  className={classes.linkedinQrCodeImage}
                  src="/linkedin-qr-code.png"
                  alt="Rael Calitro"
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default memo(ContactDialog);
